<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        style="display: block"
      >
        <b-img
          v-show="rutaDominio == 'influencerscocacola.brandme.la'"
          fluid
          src="@/assets/images/logo/coca_logo.png"
          alt="Logo"
          style="width: 15%"
        />
        <b-img
          v-show="rutaDominio != 'influencerscocacola.brandme.la'"
          fluid
          class="logo-bm d-none d-lg-block"
          src="@/assets/images/logo/Logo_BrandMe_Sponsorship.avif"
          alt="Logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center"
        style="background-size: cover"
        :style="backLogin"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-link href="https://www.instagram.com/betopasillas/" target="_blank">
            <div class="avatar-position d-flex align-items-center">
              <b-avatar
                @click="'https://www.instagram.com/betopasillas/'"
                src="@/assets/images/logo/avatar_beto.avif"
                class="avatar-login"
                size="4em"
              />
              <span class="username-logo">Beto Pasillas</span>
            </div>
          </b-link>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 mt-3 mt-sm-0"
      >
        <b-col
          class="col-12 col-md-7 px-xl-2 mx-auto"
        >
          <b-card-title 
            class="mb-1 font-weight-bold text-center avenir-heavy font-32 text-black"
            title-tag="h2">
            {{$t('register.recoverAccount')}}
          </b-card-title>
          <b-card-text class="mb-2 text-center avenir-medium">
            {{$t('register.resetPassword')}}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="$t('register.email')"
                class="avenir-medium"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    :placeholder="$t('register.email')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="blue-button"
                class="blue-button"
                block
              >
              {{$t('register.sendLink')}}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2 avenir-medium">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{$t('tracking.goBack')}}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BAvatar
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import service from '@/services/others'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      rutaDominio: window.location.host,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    backLogin() {
      if (this.rutaDominio === 'influencerscocacola.brandme.la') return `background-image: url(${require("@/assets/images/coca1.png")})`
      return `background-image: url(${require("@/assets/images/logo/beto_logo.avif")})`
    },
  },
  created() {
    this.rutaDominio = window.location.host
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          service.sendEmailPass({email: this.userEmail}, false)
            .then(response => {
              console.log(response)
              this.$router.push('/login')
                .then(() => {
                  loadToastificationContent().then((component) => {
                    this.$toast({
                      component: component.default,
                      position: 'top-right',
                      props: {
                        title: this.$t('register.sendEmail'),
                        icon: 'EditIcon',
                        variant: 'success',
                      }
                    })
                  })
                })
            }).catch(error => {
              console.log(error)
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('register.errorSend'),
                    icon: 'EditIcon',
                    variant: 'danger',
                  }
                })
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.auth-forgot-password-form {
  label {
    font-size: 14px
  }
}
@import '@core/scss/vue/pages/page-auth.scss';
</style>
